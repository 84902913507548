// Body
$body-bg:         #000 !default;
$primary:         #fff !default;
$body-color:      $primary !default;
$text-background: #141414 !default;

$keldysh-gray:   #888;
//$keldysh-gray: var(--bs-gray);

// Typography
$font-family-sans-serif: 'IBM Plex Sans', sans-serif;
$font-family-monospace: 'IBM Plex Mono', monospace;
$font-family-logo: 'TTFirsNeue-Regular', $font-family-sans-serif;
//$font-size-base: 1rem;
$line-height-base: 1.6;
$font-size-code: 0.8rem;
// Custom font sizes for different breakpoints
$font-size-base: 1rem; // Default font size (16px)
$font-size-sm: 0.85rem; // Small screens
$font-size-md: 0.925rem; // Medium screens
$font-size-lg: $font-size-base; // Large screens
$font-size-xl: 1.075rem; // Extra large screens
$font-size-xxl: 1.15rem; // XXL screens



$enable-rfs: true !default;
$enable-rounded: false !default;
$enable-shadows: false !default;

$container-max-widths: (xxl: 1500px) !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1560px
) !default;
